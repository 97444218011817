.App-header {
    background-color: #282c34dd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.BackgroundImage {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}