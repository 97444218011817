.App {
    text-align: left;
}

.Navigation {
    color: var(--text-color);
    background-color: var(--header-color);
    border: var(--text-color);
    border-width: 2px;
    border-style: solid;
    margin: 2%;
    padding: 0;
    display: flex;
    justify-content: space-between;
}

.PageLink {
    float: left;
    color: var(--text-color);
    text-decoration: none;
    font-size: xxx-large;
    font-family: 'Bebas Neue', cursive;
    margin: 10px 0 0 0;
    padding: 0 40px;
}

#orderLink {
    float: right;
}
