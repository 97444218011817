.Order {
    color: var(--text-color);
}

.OrderBackground {
    background-color: #656D4A;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: -2;
    overflow-x: hidden;
}

.orderForm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


.macaronSelection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: black;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 80%;
    z-index: 1;
}

.macaronSpinner {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.personalInfo {
    margin: auto;
    width: min-content;
}

.personalInfoBlock {
    display: block;
}

.center {
    text-align: center;
}
